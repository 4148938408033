.big-or {
    font-size: 16pt;
    margin: 1em 0em 1.2em 0em;
    border: 1px solid var(--bs-border-color-translucent);
    border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
    background-color: rgba(var(--bs-body-color-rgb), 0.03);
}

.big-or-content {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16pt;
}
