@use "../../style/mixins.scss" as *;

.data-viz {
    --header-height: 3rem;
    --footer-height: 4rem;

    display: grid;
    grid-template-areas:
        "header header header"
        "leftbar main rightbar"
        "footer footer footer";
    grid-template-columns: 20rem 1fr 20rem;
    grid-template-rows: var(--header-height) 1fr var(--footer-height);

    .data-operation-button img {
        height: 1.2rem;
    }

    .viz-loading {
        position: absolute;
        top: 50%;
        left: 50%;
    }

    .viz-header {
        grid-area: header;
        background-color: var(--bs-secondary-bg);
    }

    .viz-leftbar {
        grid-area: leftbar;
        display: grid;
        grid-template-rows: auto 1fr auto;
    }

    .viz-main {
        grid-area: main;
        overflow-y: scroll;
    }

    .viz-rightbar {
        grid-area: rightbar;
        display: grid;
        grid-template-rows: auto 1fr;
    }

    .viz-footer {
        grid-area: footer;
        display: grid;
        justify-content: center;
        align-content: center;
    }
}
