.tooltip {
    --bs-tooltip-opacity: 0.85;
    --bs-tooltip-color: var(--bs-body-color);
    --bs-tooltip-bg: var(--bs-secondary-bg);
}

[data-bs-theme="dark"] {
    .tooltip {
        --bs-tooltip-bg: var(--bs-tertiary-bg);
    }
}
