.controlled-organisations-list {
    form {
        & > * {
            width: max-content;
            font-weight: 400;
            line-height: 1;
            // uniform height
            height: 40px;

            color: var(--bs-body-color);
            background-color: var(--bs-body-bg);
            border-color: var(--bs-border-color);

            &:hover {
                color: var(--bs-body-color-tertiary);
                background-color: var(--bs-tertiary-bg);
                border-color: var(--bs-border-color);
            }

            &:active,
            &:focus,
            // additional specificity to override button bg
            &.btn:active {
                color: var(--bs-body-color);
                background-color: var(--bs-tertiary-bg);
                border-color: var(--bs-border-color);
                outline: none;
                box-shadow: none;
            }
        }

        button {
            font-size: 0.9rem;
        }
    }
}
