.metadata-editor {
    button {
        white-space: nowrap;
    }

    input[type="text"] {
        width: min(100%, 250px);
    }

    .group {
        display: grid;
        grid-template-rows: 1;
        grid-template-columns: 1fr auto;
        column-gap: 1rem;
        row-gap: 1rem;

        & > input {
            font-weight: 600;
            color: var(--bs-heading-color);
        }

        .list-group {
            /* grid-column: 1/3; */

            .list-group-item {
                padding: 0.5rem 1rem;
                display: grid;
                column-gap: 1rem;
                grid-template-rows: 1;
                grid-template-columns: 15fr 2fr 1fr;
                align-items: center;

                & > * {
                    &:nth-child(2) {
                        justify-self: center;
                    }
                    &:nth-child(3) {
                        justify-self: center;
                    }
                }
            }
        }
        input[type="text"] {
            padding-left: 0;
            outline: none;
            box-shadow: none;
            border: none;
            width: 100%;

            transition: color 250ms;
            &:focus {
                color: var(--bs-heading-color);
            }
        }

        input[type="checkbox"] {
            cursor: pointer;

            transition: border-color 200ms;
            &:hover {
                border-color: var(--bs-secondary-color);
            }
        }

        form {
            grid-column: 1/2;
        }
    }
}
