.create-tag-object-modal-form {
    #aliases-field {
        .list-group-item {
            display: grid;
            grid-template-columns: 1fr 2rem;

            input {
                border: unset;
                box-shadow: unset;
            }
        }
    }
}
