.import-wizard-options {
    input[type="number"] {
        width: min(100%, 80px);
    }
}

.import-wizard {
    .page-header {
        font-size: 16pt;
        padding-left: 0;
        padding-right: 0;
    }

    .page-content {
        margin: 0;
    }

    .footer {
        display: flex;
        justify-content: flex-end;
    }

    button:first-child {
        margin-right: 10px;
    }
}
