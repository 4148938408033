@use "sass:list" as list;

@use "bootstrap" as *;

$COLOURS: (
    // BOOTSTRAP OVERWRITES
    "--bs-body-color": (hsl(210, 11%, 15%), hsl(210, 11%, 97%)),
    // OTHER
    "--timeline-sprite": (hsl(39, 100%, 50%), hsl(39, 80%, 45%)),
    "--timeline-sprite-debug": (hsl(345, 100%, 50%), hsl(345, 80%, 45%)),
    "--toast-warning-header": (hsl(56, 100%, 76%), hsl(60, 90%, 46%)),
    "--video-control-button-bg": (hsl(0, 0%, 90%), hsl(0, 0%, 70%)),
    "--video-control-button-bg-hover": (hsl(0, 0%, 80%), hsl(0, 0%, 60%)),
    "--clr-checkered-pattern-filter": (hsla(0, 0%, 95%, 90%), hsla(0, 0%, 20%, 90%))
);

@each $variable, $light-and-dark in $COLOURS {
    :root {
        #{$variable}: list.nth($light-and-dark, 1);
    }

    [data-bs-theme="dark"] {
        #{$variable}: list.nth($light-and-dark, 2);
    }
}

code {
    color: #e01a76;
}
