@use "bootstrap" as *;

html {
    font-size: 14px;
}

@include media-breakpoint-up(md) {
    html {
        font-size: 16px;
    }
}
