.tags-header {
    display: grid;
    height: 100%;
    grid-template-columns: auto 1fr auto;
    grid-template-areas: "empty views reps";
}

.tags-views {
    grid-area: views;
}

.tags-reps {
    grid-area: reps;
}
