@use "bootstrap" as *;

.container,
.review {
    --bs-gutter-x: 1.5rem !important;
}

@include media-breakpoint-up(lg) {

    .container,
    .review {
        --bs-gutter-x: 4rem !important;
    }
}

// Avoid extra padding for nested containers
.container .container {
    --bs-gutter: 0 !important;

    .container {
        --bs-gutter: 0 !important;
    }
}
