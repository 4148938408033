button.refresh-button {
    color: var(--bs-secondary-color);
    font-size: 18px !important;
    line-height: 0.8 !important;
    border: none;
    background-color: unset;
    padding: auto;

    &:hover,
    &:focus,
    &:active {
        background-color: unset !important;
        color: var(--bs-heading-color);
    }
}
