.user-settings {
    .tab-content>* {
        width: min(25rem, 100%);
    }

    .advanced-tab,
    .appearance-tab,
    .notifications-tab {
        .list-group-item {
            background-color: var(--bs-tertiary-bg);

            .form-label {
                margin: 0;
                display: grid;
                grid-template-columns: 1fr 1rem 1rem;
                gap: 2rem;
                align-items: center;
            }
        }
    }

    .security-tab {
        input {
            background-color: var(--bs-tertiary-bg);
        }
    }
}
