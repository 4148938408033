/* CHROMIUM */
*::-webkit-scrollbar {
    width: 0.25em;
}

*::-webkit-scrollbar-track {
    margin-block: 0.5em;
    border-radius: var(--bs-border-radius);

    &:hover {
        background-color: var(--bs-secondary-bg);
    }
}

*::-webkit-scrollbar-thumb {
    border-radius: var(--bs-border-radius);
    background-color: rgba(var(--bs-body-color-rgb), 0.7);

    &:hover {
        background-color: rgba(var(--bs-body-color-rgb), 0.8);
    }
}
