.copy-to-clipboard {
    display: block;
    position: relative;
    padding: 1.4rem 1.2rem 1rem 1.2rem;
    min-width: 100px;
    width: 100%;
    text-align: center;

    .copy-to-clipboard-button {
        font-size: 10pt;
        position: absolute;
        top: 0.5rem;
        right: 0.6rem;
        color: inherit;
        border: none;
        background-color: transparent;

        &:focus {
            box-shadow: none;
        }
    }

    &:focus {
        box-shadow: none;
    }
}
