.status-bar {
    select {
        padding: 0;
        cursor: pointer;
        background-color: unset;
        border: 0;

        // hide default arrow
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-overflow: "";
        &::-ms-expand {
            display: none;
        }

        &:active,
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .button-with-icon {
        font-size: 14pt;
    }

    // Reduce height of react-select component
    .react-select__control {
        min-height: unset;
        overflow-y: hidden;

        .react-select__value-container,
        .react-select__input-container,
        .react-select__indicator {
            padding-top: 0;
            padding-bottom: 0;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    // Hide second line of text when showing the label as the selected value
    .react-select__single-value .second-line {
        display: none;
    }
}
