.configure-user {
    .list-group {
        max-width: 30rem;
        .list-group-item {
            background-color: var(--bs-tertiary-bg);

            .form-label {
                margin: 0;
                display: grid;
                grid-template-columns: 1fr 1rem 1rem;
                gap: 2rem;
                align-items: center;
            }
        }
    }
}
