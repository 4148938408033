.item {
    cursor: pointer;
    margin: 0.2rem 0;
}
.active-item {
    border: #a9a9ff 2px solid !important;
}

.media-list {
    padding: 0.5rem;
    background-color: var(--bs-tertiary-bg);
    max-height: 825px;
    overflow: auto;
}
