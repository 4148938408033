.organisation {
    .organisation-details {
        .organisation-id {
            // better align with #
            margin-bottom: -2px;
        }

        .details-table {
            max-width: 35rem;
        }
    }

    .organisation-users {
        header {
            pointer-events: none;
            cursor: none;

            h6 {
                font-weight: 600;
            }
        }

        .accordion-button,
        header {
            display: grid;
            padding: 0.8rem 1rem;
            column-gap: 1rem;
            justify-content: center;
            align-items: center;
            grid-template-rows: 1;
            grid-template-columns: 1fr 6fr 10fr 3fr 1fr;

            span {
                display: block;
            }
        }
    }
}
