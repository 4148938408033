svg.timeline-d3-chart {
    background-color: var(--bs-tertiary-bg);
    position: relative;
    overflow: hidden;

    text {
        fill: var(--bs-body-color) !important;
    }
    .mouse-line {
        shape-rendering: crispEdges;
    }

    .sprite {
        fill: var(--timeline-sprite);
    }
    .sprite-debug {
        fill: var(--timeline-sprite-debug);
    }

    .grid {
        opacity: 1;
        text-anchor: initial;

        .grid-bg {
            fill: var(--bs-secondary-bg);
        }
    }

    .tick text {
        user-select: none;
    }
}

.review-timeline .loading-label {
    font-size: 15pt;
}

[data-bs-theme="dark"] {
    svg.timeline-d3-chart {
        background-color: var(--bs-body-bg);

        .grid-bg {
            fill: var(--bs-secondary-bg);
        }
    }
}
