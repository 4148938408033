.react-select {
    &__control {
        cursor: pointer !important;
        border: var(--bs-border-width) solid var(--bs-border-color) !important;
        border-radius: var(--bs-border-radius) !important;
        background-color: var(--bs-body-bg) !important;
        overflow: hidden;
    }

    &__input-container {
        color: var(--bs-body-color) !important;
    }

    &__value-container {
        background-color: var(--bs-body-bg);
    }

    &__single-value {
        color: var(--bs-body-color) !important;
    }

    &__multi-value {
        background-color: var(--bs-secondary-bg) !important;

        &__label {
            color: var(--bs-body-color) !important;
        }

        &__remove {
            svg {
                fill: var(--bs-secondary) !important;
            }
        }
    }

    &__menu {
        background-color: var(--bs-body-bg) !important;
    }

    &__option {
        cursor: pointer !important;
    }

    &__option--is-focused {
        background-color: var(--bs-tertiary-bg) !important;
    }

    &__option--is-selected {
        color: var(--bs-body-color) !important;
        background-color: var(--bs-secondary-bg) !important;
    }
}
