@import "../../../../style/mixins.scss";

.viz-list {
    display: flex;
    flex-wrap: wrap;
}

.viz-list-item {
    position: relative;
    width: 12rem;
    height: 10rem;
    margin: 1rem 0rem 0rem 1rem;
    padding: 1rem 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: background-color 250ms;
    border-radius: var(--bs-border-radius);

    &:hover {
        background-color: var(--bs-secondary-bg);
    }

    &.selected {
        background-color: rgba(var(--bs-primary-rgb), 0.1);

        &:hover {
            background-color: rgba(var(--bs-primary-rgb), 0.2);
        }
    }

    .viz-list-item-thumbnail {
        width: 9.5rem;
        height: 5.34rem;
        @include checkered-background(1em);
    }

    .viz-list-item-title {
        margin-top: 0.3rem;
        text-align: center;
        max-width: 100%;
        padding: 0 0.8rem;
        text-overflow: ellipsis;
        overflow: hidden;
        text-wrap: nowrap;
    }

    .viz-list-item-subtitle {
        margin-top: 0.2rem;
        font-size: 0.7rem;
        text-align: center;
    }

    &:hover {
        .viz-list-item-show-context-menu {
            visibility: visible;
        }
    }

    .viz-list-item-show-context-menu {
        visibility: hidden;
        position: absolute;
        top: 0.3rem;
        right: 0.3rem;
        width: 1.6rem;
        height: 1.6rem;
        padding: 0;
        margin: 0;
        z-index: 999;

        display: flex;
        align-items: center;
        justify-content: center;

        outline: none;
        border: none;
    }

    .viz-list-item-context-menu {
        // Top and left values set by component itself via props
        position: fixed;
        z-index: 1000;
        list-style: none;
        background-color: var(--bs-tertiary-bg);

        & > * {
            width: 100%;

            &:hover {
                background-color: var(--bs-secondary-bg);
            }
        }
    }
}

[data-bs-theme="dark"] {
    .viz-list-item-show-context-menu {
        background-color: var(--bs-btn-hover-bg);

        &:hover {
            background-color: var(--bs-btn-bg);
        }
    }
}
