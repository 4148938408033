.error-page {
    max-width: 35rem;
    min-height: 65vh;

    h2 {
        text-shadow: 2px 2px 4px var(--bs-secondary-bg);
        font-size: 4rem;
    }

    button {
        width: max-content;
    }
}
