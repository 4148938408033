.label,
.milliseconds {
    display: inline;
    color: var(--bs-tertiary-color);
}
.mediaItem {
    padding: 5px;
    font-size: 15px;
}
.mediaItem div {
    margin: 0px;
}
.mediaItem div:not(:first-of-type) {
    font-size: 13px;
}
.preview {
    background-color: black;
    height: 100%;
    width: 90%;
}
.milliseconds {
    font-size: 11px !important;
}
