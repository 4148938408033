.button-with-icon {
    border: none;
    box-shadow: none;
    background: none;
    font-size: 1.3rem;

    transition: color 200ms;
    color: var(--bs-tertiary-color);

    &:hover {
        color: var(--bs-body-color);
    }
}
