.accordion {
    .accordion-button {
        --bs-accordion-active-color: var(--bs-color-emphasis);
        --bs-accordion-active-bg: var(--bs-tertiary-bg);

        &:focus,
        &:not(.collapsed) {
            box-shadow: none;
        }
    }
}
